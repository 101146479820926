/* eslint-disable */
import * as Types from '../types.generated';

import {
    InstitutionalMemoryFieldsFragment,
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    EntityDomainFragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    InstitutionalMemoryFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    EntityDomainFragmentDoc,
} from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetDataProductQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetDataProductQuery = { __typename?: 'Query' } & {
    dataProduct?: Types.Maybe<{ __typename?: 'DataProduct' } & DataProductFieldsFragment>;
};

export type DataProductFieldsFragment = { __typename?: 'DataProduct' } & {
    properties?: Types.Maybe<
        { __typename?: 'DataProductProperties' } & {
            customProperties?: Types.Maybe<
                Array<{ __typename?: 'CustomPropertiesEntry' } & Pick<Types.CustomPropertiesEntry, 'key' | 'value'>>
            >;
        }
    >;
    institutionalMemory?: Types.Maybe<{ __typename?: 'InstitutionalMemory' } & InstitutionalMemoryFieldsFragment>;
} & DataProductSearchFieldsFragment;

export type DataProductSearchFieldsFragment = { __typename?: 'DataProduct' } & Pick<
    Types.DataProduct,
    'urn' | 'type'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DataProductProperties' } & Pick<
                Types.DataProductProperties,
                'name' | 'description' | 'externalUrl'
            >
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        tags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        domain?: Types.Maybe<{ __typename?: 'DomainAssociation' } & EntityDomainFragment>;
        entities?: Types.Maybe<{ __typename?: 'SearchResults' } & Pick<Types.SearchResults, 'total'>>;
    };

export type CreateDataProductMutationVariables = Types.Exact<{
    input: Types.CreateDataProductInput;
}>;

export type CreateDataProductMutation = { __typename?: 'Mutation' } & {
    createDataProduct?: Types.Maybe<{ __typename?: 'DataProduct' } & DataProductSearchFieldsFragment>;
};

export type UpdateDataProductMutationVariables = Types.Exact<{
    urn: Types.Scalars['String'];
    input: Types.UpdateDataProductInput;
}>;

export type UpdateDataProductMutation = { __typename?: 'Mutation' } & {
    updateDataProduct?: Types.Maybe<{ __typename?: 'DataProduct' } & DataProductSearchFieldsFragment>;
};

export type DeleteDataProductMutationVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type DeleteDataProductMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'deleteDataProduct'>;

export type BatchSetDataProductMutationVariables = Types.Exact<{
    input: Types.BatchSetDataProductInput;
}>;

export type BatchSetDataProductMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'batchSetDataProduct'>;

export const DataProductSearchFieldsFragmentDoc = gql`
    fragment dataProductSearchFields on DataProduct {
        urn
        type
        properties {
            name
            description
            externalUrl
        }
        ownership {
            ...ownershipFields
        }
        tags {
            ...globalTagsFields
        }
        glossaryTerms {
            ...glossaryTerms
        }
        domain {
            ...entityDomain
        }
        entities(input: { start: 0, count: 0, query: "*" }) {
            total
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${EntityDomainFragmentDoc}
`;
export const DataProductFieldsFragmentDoc = gql`
    fragment dataProductFields on DataProduct {
        ...dataProductSearchFields
        properties {
            customProperties {
                key
                value
            }
        }
        institutionalMemory {
            ...institutionalMemoryFields
        }
    }
    ${DataProductSearchFieldsFragmentDoc}
    ${InstitutionalMemoryFieldsFragmentDoc}
`;
export const GetDataProductDocument = gql`
    query getDataProduct($urn: String!) {
        dataProduct(urn: $urn) {
            ...dataProductFields
        }
    }
    ${DataProductFieldsFragmentDoc}
`;

/**
 * __useGetDataProductQuery__
 *
 * To run a query within a React component, call `useGetDataProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataProductQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetDataProductQuery(
    baseOptions: Apollo.QueryHookOptions<GetDataProductQuery, GetDataProductQueryVariables>,
) {
    return Apollo.useQuery<GetDataProductQuery, GetDataProductQueryVariables>(GetDataProductDocument, baseOptions);
}
export function useGetDataProductLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDataProductQuery, GetDataProductQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDataProductQuery, GetDataProductQueryVariables>(GetDataProductDocument, baseOptions);
}
export type GetDataProductQueryHookResult = ReturnType<typeof useGetDataProductQuery>;
export type GetDataProductLazyQueryHookResult = ReturnType<typeof useGetDataProductLazyQuery>;
export type GetDataProductQueryResult = Apollo.QueryResult<GetDataProductQuery, GetDataProductQueryVariables>;
export const CreateDataProductDocument = gql`
    mutation createDataProduct($input: CreateDataProductInput!) {
        createDataProduct(input: $input) {
            ...dataProductSearchFields
        }
    }
    ${DataProductSearchFieldsFragmentDoc}
`;
export type CreateDataProductMutationFn = Apollo.MutationFunction<
    CreateDataProductMutation,
    CreateDataProductMutationVariables
>;

/**
 * __useCreateDataProductMutation__
 *
 * To run a mutation, you first call `useCreateDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataProductMutation, { data, loading, error }] = useCreateDataProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataProductMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateDataProductMutation, CreateDataProductMutationVariables>,
) {
    return Apollo.useMutation<CreateDataProductMutation, CreateDataProductMutationVariables>(
        CreateDataProductDocument,
        baseOptions,
    );
}
export type CreateDataProductMutationHookResult = ReturnType<typeof useCreateDataProductMutation>;
export type CreateDataProductMutationResult = Apollo.MutationResult<CreateDataProductMutation>;
export type CreateDataProductMutationOptions = Apollo.BaseMutationOptions<
    CreateDataProductMutation,
    CreateDataProductMutationVariables
>;
export const UpdateDataProductDocument = gql`
    mutation updateDataProduct($urn: String!, $input: UpdateDataProductInput!) {
        updateDataProduct(urn: $urn, input: $input) {
            ...dataProductSearchFields
        }
    }
    ${DataProductSearchFieldsFragmentDoc}
`;
export type UpdateDataProductMutationFn = Apollo.MutationFunction<
    UpdateDataProductMutation,
    UpdateDataProductMutationVariables
>;

/**
 * __useUpdateDataProductMutation__
 *
 * To run a mutation, you first call `useUpdateDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataProductMutation, { data, loading, error }] = useUpdateDataProductMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataProductMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateDataProductMutation, UpdateDataProductMutationVariables>,
) {
    return Apollo.useMutation<UpdateDataProductMutation, UpdateDataProductMutationVariables>(
        UpdateDataProductDocument,
        baseOptions,
    );
}
export type UpdateDataProductMutationHookResult = ReturnType<typeof useUpdateDataProductMutation>;
export type UpdateDataProductMutationResult = Apollo.MutationResult<UpdateDataProductMutation>;
export type UpdateDataProductMutationOptions = Apollo.BaseMutationOptions<
    UpdateDataProductMutation,
    UpdateDataProductMutationVariables
>;
export const DeleteDataProductDocument = gql`
    mutation deleteDataProduct($urn: String!) {
        deleteDataProduct(urn: $urn)
    }
`;
export type DeleteDataProductMutationFn = Apollo.MutationFunction<
    DeleteDataProductMutation,
    DeleteDataProductMutationVariables
>;

/**
 * __useDeleteDataProductMutation__
 *
 * To run a mutation, you first call `useDeleteDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataProductMutation, { data, loading, error }] = useDeleteDataProductMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useDeleteDataProductMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteDataProductMutation, DeleteDataProductMutationVariables>,
) {
    return Apollo.useMutation<DeleteDataProductMutation, DeleteDataProductMutationVariables>(
        DeleteDataProductDocument,
        baseOptions,
    );
}
export type DeleteDataProductMutationHookResult = ReturnType<typeof useDeleteDataProductMutation>;
export type DeleteDataProductMutationResult = Apollo.MutationResult<DeleteDataProductMutation>;
export type DeleteDataProductMutationOptions = Apollo.BaseMutationOptions<
    DeleteDataProductMutation,
    DeleteDataProductMutationVariables
>;
export const BatchSetDataProductDocument = gql`
    mutation batchSetDataProduct($input: BatchSetDataProductInput!) {
        batchSetDataProduct(input: $input)
    }
`;
export type BatchSetDataProductMutationFn = Apollo.MutationFunction<
    BatchSetDataProductMutation,
    BatchSetDataProductMutationVariables
>;

/**
 * __useBatchSetDataProductMutation__
 *
 * To run a mutation, you first call `useBatchSetDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchSetDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchSetDataProductMutation, { data, loading, error }] = useBatchSetDataProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchSetDataProductMutation(
    baseOptions?: Apollo.MutationHookOptions<BatchSetDataProductMutation, BatchSetDataProductMutationVariables>,
) {
    return Apollo.useMutation<BatchSetDataProductMutation, BatchSetDataProductMutationVariables>(
        BatchSetDataProductDocument,
        baseOptions,
    );
}
export type BatchSetDataProductMutationHookResult = ReturnType<typeof useBatchSetDataProductMutation>;
export type BatchSetDataProductMutationResult = Apollo.MutationResult<BatchSetDataProductMutation>;
export type BatchSetDataProductMutationOptions = Apollo.BaseMutationOptions<
    BatchSetDataProductMutation,
    BatchSetDataProductMutationVariables
>;
