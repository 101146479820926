/* eslint-disable */
import * as Types from '../types.generated';

import {
    PlatformFieldsFragment,
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    EntityDomainFragment,
    EntityDataProduct_AccessTokenMetadata_Fragment,
    EntityDataProduct_Assertion_Fragment,
    EntityDataProduct_Chart_Fragment,
    EntityDataProduct_Container_Fragment,
    EntityDataProduct_CorpGroup_Fragment,
    EntityDataProduct_CorpUser_Fragment,
    EntityDataProduct_Dashboard_Fragment,
    EntityDataProduct_DataFlow_Fragment,
    EntityDataProduct_DataHubPolicy_Fragment,
    EntityDataProduct_DataHubRole_Fragment,
    EntityDataProduct_DataHubView_Fragment,
    EntityDataProduct_DataJob_Fragment,
    EntityDataProduct_DataPlatform_Fragment,
    EntityDataProduct_DataPlatformInstance_Fragment,
    EntityDataProduct_DataProcessInstance_Fragment,
    EntityDataProduct_DataProduct_Fragment,
    EntityDataProduct_Dataset_Fragment,
    EntityDataProduct_Domain_Fragment,
    EntityDataProduct_GlossaryNode_Fragment,
    EntityDataProduct_GlossaryTerm_Fragment,
    EntityDataProduct_MlFeature_Fragment,
    EntityDataProduct_MlFeatureTable_Fragment,
    EntityDataProduct_MlModel_Fragment,
    EntityDataProduct_MlModelGroup_Fragment,
    EntityDataProduct_MlPrimaryKey_Fragment,
    EntityDataProduct_Notebook_Fragment,
    EntityDataProduct_OwnershipTypeEntity_Fragment,
    EntityDataProduct_Post_Fragment,
    EntityDataProduct_QueryEntity_Fragment,
    EntityDataProduct_Role_Fragment,
    EntityDataProduct_SchemaFieldEntity_Fragment,
    EntityDataProduct_Tag_Fragment,
    EntityDataProduct_Test_Fragment,
    EntityDataProduct_VersionedDataset_Fragment,
    NonRecursiveDataFlowFieldsFragment,
    InstitutionalMemoryFieldsFragment,
    DeprecationFieldsFragment,
    EmbedFieldsFragment,
    DataPlatformInstanceFieldsFragment,
    ParentContainersFieldsFragment,
    BrowsePathV2FieldsFragment,
    InputFieldsFieldsFragment,
    EntityContainerFragment,
    ParentNodesFieldsFragment,
    GlossaryNodeFragment,
    ParentDomainsFieldsFragment,
    DomainEntitiesFieldsFragment,
    NonRecursiveMlFeatureTableFragment,
    NonRecursiveMlFeatureFragment,
    NonRecursiveMlPrimaryKeyFragment,
    SchemaMetadataFieldsFragment,
    NonConflictingPlatformFieldsFragment,
    EntityDisplayNameFields_AccessTokenMetadata_Fragment,
    EntityDisplayNameFields_Assertion_Fragment,
    EntityDisplayNameFields_Chart_Fragment,
    EntityDisplayNameFields_Container_Fragment,
    EntityDisplayNameFields_CorpGroup_Fragment,
    EntityDisplayNameFields_CorpUser_Fragment,
    EntityDisplayNameFields_Dashboard_Fragment,
    EntityDisplayNameFields_DataFlow_Fragment,
    EntityDisplayNameFields_DataHubPolicy_Fragment,
    EntityDisplayNameFields_DataHubRole_Fragment,
    EntityDisplayNameFields_DataHubView_Fragment,
    EntityDisplayNameFields_DataJob_Fragment,
    EntityDisplayNameFields_DataPlatform_Fragment,
    EntityDisplayNameFields_DataPlatformInstance_Fragment,
    EntityDisplayNameFields_DataProcessInstance_Fragment,
    EntityDisplayNameFields_DataProduct_Fragment,
    EntityDisplayNameFields_Dataset_Fragment,
    EntityDisplayNameFields_Domain_Fragment,
    EntityDisplayNameFields_GlossaryNode_Fragment,
    EntityDisplayNameFields_GlossaryTerm_Fragment,
    EntityDisplayNameFields_MlFeature_Fragment,
    EntityDisplayNameFields_MlFeatureTable_Fragment,
    EntityDisplayNameFields_MlModel_Fragment,
    EntityDisplayNameFields_MlModelGroup_Fragment,
    EntityDisplayNameFields_MlPrimaryKey_Fragment,
    EntityDisplayNameFields_Notebook_Fragment,
    EntityDisplayNameFields_OwnershipTypeEntity_Fragment,
    EntityDisplayNameFields_Post_Fragment,
    EntityDisplayNameFields_QueryEntity_Fragment,
    EntityDisplayNameFields_Role_Fragment,
    EntityDisplayNameFields_SchemaFieldEntity_Fragment,
    EntityDisplayNameFields_Tag_Fragment,
    EntityDisplayNameFields_Test_Fragment,
    EntityDisplayNameFields_VersionedDataset_Fragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    PlatformFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    EntityDomainFragmentDoc,
    EntityDataProductFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    InstitutionalMemoryFieldsFragmentDoc,
    DeprecationFieldsFragmentDoc,
    EmbedFieldsFragmentDoc,
    DataPlatformInstanceFieldsFragmentDoc,
    ParentContainersFieldsFragmentDoc,
    BrowsePathV2FieldsFragmentDoc,
    InputFieldsFieldsFragmentDoc,
    EntityContainerFragmentDoc,
    ParentNodesFieldsFragmentDoc,
    GlossaryNodeFragmentDoc,
    ParentDomainsFieldsFragmentDoc,
    DomainEntitiesFieldsFragmentDoc,
    NonRecursiveMlFeatureTableFragmentDoc,
    NonRecursiveMlFeatureFragmentDoc,
    NonRecursiveMlPrimaryKeyFragmentDoc,
    SchemaMetadataFieldsFragmentDoc,
    NonConflictingPlatformFieldsFragmentDoc,
    EntityDisplayNameFieldsFragmentDoc,
} from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetDataPlatformQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetDataPlatformQuery = { __typename?: 'Query' } & {
    dataPlatform?: Types.Maybe<{ __typename?: 'DataPlatform' } & PlatformFieldsFragment>;
};

export const GetDataPlatformDocument = gql`
    query getDataPlatform($urn: String!) {
        dataPlatform(urn: $urn) {
            ...platformFields
        }
    }
    ${PlatformFieldsFragmentDoc}
`;

/**
 * __useGetDataPlatformQuery__
 *
 * To run a query within a React component, call `useGetDataPlatformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataPlatformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataPlatformQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetDataPlatformQuery(
    baseOptions: Apollo.QueryHookOptions<GetDataPlatformQuery, GetDataPlatformQueryVariables>,
) {
    return Apollo.useQuery<GetDataPlatformQuery, GetDataPlatformQueryVariables>(GetDataPlatformDocument, baseOptions);
}
export function useGetDataPlatformLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDataPlatformQuery, GetDataPlatformQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDataPlatformQuery, GetDataPlatformQueryVariables>(
        GetDataPlatformDocument,
        baseOptions,
    );
}
export type GetDataPlatformQueryHookResult = ReturnType<typeof useGetDataPlatformQuery>;
export type GetDataPlatformLazyQueryHookResult = ReturnType<typeof useGetDataPlatformLazyQuery>;
export type GetDataPlatformQueryResult = Apollo.QueryResult<GetDataPlatformQuery, GetDataPlatformQueryVariables>;
